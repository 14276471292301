// old style guide
$gray: #333333;
$gray-2: #828282;
$gray-light: #3333331a;
$gray-lighter: #e9ecef;
$gray-lightest: #f8f8f8;
$gray-gainsboro: #dddddd;
$gray-dark: #2c2e39;
$gray-border: #d4d4d4;
$blue-tufts: #3e8ede;
$blue-tufts-light: #2a8ef2;
$blue-purple: #0b5ed7;

// n4 style guide colors
$navy-blue: #142546;
$navy-blue-50: #f3f4f6;
$navy-blue-80: #142546cc;
$navy-blue-100: #e7e9ec;
$navy-blue-200: #d0d3da;
$navy-blue-400: #a1a8b5;
$navy-blue-600: #727c9d;
$navy-blue-800: #43516b;
$navy-blue-900: #2c3b59;
$navy-blue-950: #20304f;
$orange-50: #fef9f0;
$primary-blue: #4a7fe5;
$sky-blue-400: #98c1ff;
$light-green-200: #e1f7c6;
$peach-300: #fbc379;
$white: #ffffff;
$white-80: #ffffffcc;
$black: #000;

$lavender: #d6cfff;
$lavender-offset: #bfb2f0; // More blue and darker

$merigold: #ffd195;
$merigold-offset: #ffc377; // More yellow and slightly darker

$sky: #b9d5ff;
$sky-offset: #9bbef7; // More blue and darker

$lime: #e1f7c6;
$lime-offset: #c4e7a4; // More yellow-green and slightly darker

// others used in app
$cream-10: #f1ece4;
$sky-50: #cbddf8;
$success: #179f2d;
$error: #ea3323;
