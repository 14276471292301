$imgix: 'https://ht-prod.imgix.net';
@use '../../../../../../styles/variables' as variables;
@use '../../../../../../styles/colors' as colors;

.scrollToExplore {
  display: none;
  text-decoration: none;
  cursor: pointer;
  @media #{variables.$above-md} {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    position: absolute;
    left: 50px;
    bottom: 43px;

    @media #{variables.$desktop-wide} {
      gap: 19.667px;
      left: 96px;
      bottom: 77px;
    }

    i.icon {
      position: relative;
      width: 48px;
      height: 48px;

      @media #{variables.$desktop-wide} {
        width: 59px;
        height: 59px;
      }
    }
    span {
      font-size: 16px;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: -0.48px;
      text-align: left;
      color: white;

      @media #{variables.$desktop-wide} {
        font-size: 19.667px;
        letter-spacing: -0.59px;
      }
    }
  }
}
