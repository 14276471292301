$imgix: 'https://ht-prod.imgix.net';
@use '../../../../../styles/variables' as variables;
@use '../../../../../styles/colors' as colors;

.ht-hero-lower {
  background-color: #fef9f0;
  padding: 80px 48px 80px 48px;
  display: flex;
  flex-direction: column;
  gap: 108px;

  @media #{variables.$xs} {
    padding: 20px 18px 64px 18px;
    gap: 72px;
  }

  @media #{variables.$desktop-wide} {
    padding: 96px 96px 128px 96px;
    gap: 120px;
  }
}
