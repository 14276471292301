$imgix: 'https://ht-prod.imgix.net';
@use '../../../../../styles/variables' as variables;
@use '../../../../../styles/colors' as colors;

$spacing: (
  mobile: 16px,
  desktop: 48px,
  wide: 96px,
);

.ht-testimonials {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: map-get($spacing, desktop);
  background-color: colors.$orange-50;
  padding: 96px 48px 96px 48px;

  @media #{variables.$xs} {
    padding: 48px 16px 48px 16px;
  }

  @media #{variables.$desktop-wide} {
    padding: 96px 96px 96px 96px;
  }

  .header {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 12px;

    @media #{variables.$above-md} {
      gap: 0px;
    }
  }

  .title {
    max-width: 664px;
    height: 53px;
    font-size: 40px;
    font-weight: variables.$font-weight-semibold;
    line-height: 52.8px;
    letter-spacing: -0.03em;
    text-align: left;
    overflow: hidden;
    color: colors.$navy-blue;

    @media #{variables.$xs} {
      height: 48px;
    }

    @media #{variables.$above-md} {
      font-size: 48px;
    }

    @media #{variables.$desktop-wide} {
      font-size: 56px;
    }
  }

  .innerContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media #{variables.$above-md} {
      display: flex;
      flex-direction: row;
      gap: 0;
    }
  }

  .btnContainer {
    position: relative;
    width: 100%;
    height: auto;

    @media #{variables.$above-md} {
      display: flex !important;
      justify-content: flex-end !important;
      margin-top: auto;
    }
  }

  .description {
    width: 100%;
    height: auto;
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.48px;
    text-align: left !important;
    color: colors.$navy-blue-80;
    margin-bottom: 0;
    display: flex !important;
    align-items: flex-end;

    @media #{variables.$above-md} {
      font-size: 18px;
      margin-top: 16px;
      width: 300%;
      line-height: 120%;
      letter-spacing: -0.54px;
    }
  }

  .viewBtn {
    height: 42px;
    font-size: 16px;
    width: auto;
    color: colors.$white;

    @media #{variables.$desktop-wide} {
      font-size: 18px;
      height: 46px;
    }
  }

  .carouselContainer {
    position: relative;
    height: 100%;
    width: calc(100% + map-get($spacing, desktop));

    @media #{variables.$xs} {
      width: calc(100% + map-get($spacing, mobile));
    }

    @media #{variables.$desktop-wide} {
      width: calc(100% + map-get($spacing, wide));
    }
  }

  li {
    max-width: 552px;
  }
}
