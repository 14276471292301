$imgix: 'https://ht-prod.imgix.net';
@use '../../../../../../styles/variables' as variables;
@use '../../../../../../styles/colors' as colors;

.ht-hero-lower-section-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;

  @media #{variables.$xs} {
    display: flex;
    flex-direction: column;
    gap: 40px !important;
  }

  .headline {
    max-width: 850px;
    height: auto;
    color: #100f4833;
    font-size: 48px;
    font-style: normal;
    font-weight: variables.$font-weight-normal;
    line-height: 130%;
    letter-spacing: -1.44px;

    div {
      display: inline-block;
      position: relative;
    }

    span {
      position: absolute;
      width: 0;
      height: 100%;
      color: colors.$navy-blue;
      left: 0;
      overflow: hidden;
    }

    @media #{variables.$md, variables.$xs} {
      max-width: 392px;
      font-size: 37px;
      letter-spacing: -0.96px;
    }

    @media (max-width: 360px) {
      max-width: 100%;
      font-size: 32px;
    }

    @media #{variables.$desktop-wide} {
      max-width: 1140px;
      font-size: 64px;
      line-height: 120%;
      font-weight: 500;
      letter-spacing: -1.92px;
    }
  }

  .rightContainer {
    display: flex;
    max-width: 360px;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px;
    opacity: 0.5;

    @media (max-width: 360px) {
      max-width: 100%;
    }

    @media #{variables.$desktop-wide} {
      max-width: 452px;
    }

    @media #{variables.$xs} {
      margin-left: 0;
    }

    @media #{variables.$md} {
      margin-left: 20px;
    }
  }

  .textContainerRight {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-self: flex-end;
  }

  .titleRight {
    width: 270px;
    color: colors.$black;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.54px;
    overflow: hidden;

    @media #{variables.$desktop-wide} {
      width: auto;
      font-size: 32px;
      font-weight: variables.$font-weight-semibold;
      letter-spacing: -0.96px;
    }
  }

  .textRight {
    color: colors.$black;
    width: 100%;
    height: auto;
    font-size: 16px;
    font-style: normal;
    align-self: stretch;
    font-weight: variables.$font-weight-normal;
    line-height: 150%;
    letter-spacing: -0.48px;

    @media #{variables.$desktop-wide} {
      font-size: 20px;
      line-height: 140%;
      letter-spacing: -0.6px;
    }
  }

  .label {
    width: 144px;
    height: 48px;
    margin-top: 28px;
  }
}
