$imgix: 'https://ht-prod.imgix.net';
@use '../../../../../../styles/variables' as variables;
@use '../../../../../../styles/colors' as colors;

.ht-testimonial-card {
  display: flex;
  padding: 32px;
  gap: 24px;
  border-radius: 20px;
  background-color: colors.$white;
  p {
    font-weight: 400;
  }

  @media #{variables.$xs} {
    max-width: 380px;
    min-height: 381px;
    p {
      padding-bottom: 2rem;
    }
  }

  @media #{variables.$above-md} {
    min-width: 520px;
    max-width: 520px;
    min-height: 420px;
  }

  &.product-testimonial {
    gap: 32px;

    &.mobile {
      background-color: transparent;
      border-radius: 0;
      border-top: 1px solid colors.$gray-border;
      padding: 32px 0 0 0;
    }

    @media #{variables.$xs} {
      min-height: 302px;
      max-width: 394px;
    }

    @media #{variables.$above-md} {
      width: 620px;
      min-height: 252px !important;
    }

    @media #{variables.$desktop-wide} {
      width: 948px;
      height: 253px;
      background-color: transparent;
      border-radius: 0;
      gap: 40px;
      border-top: 1px solid colors.$gray-border;
      padding: 0;
      padding-top: 40px;
      display: block;
    }

    p {
      @media #{variables.$desktop-wide} {
        width: 536.342px;
        height: 125px;
        font-size: 20.8px;
        font-weight: 400;
        line-height: 41.6px;
        letter-spacing: -0.624px;
      }
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    &.product-testimonial {
      @media #{variables.$desktop-wide} {
        gap: 40px;
      }
    }

    &-text {
      font-weight: 400;
      font-size: 24px;
      line-height: 140%;
      letter-spacing: -0.72px;
      color: colors.$navy-blue;

      &.product-testimonial {
        font-size: 20px;
        line-height: 140%;
        letter-spacing: -0.6px;

        @media #{variables.$above-md} {
          width: 536.342px;
        }

        @media #{variables.$desktop-wide} {
          height: 125px;
        }
      }

      &-read-more {
        cursor: pointer;
        color: colors.$primary-blue;

        @media #{variables.$desktop-wide} {
          display: none;
        }
      }
    }
  }

  &-rating {
    text-align: end;

    &.mobile {
      text-align: start;
    }
  }

  &-testimonialer,
  &-rating {
    display: flex;
    gap: 20px;
    align-items: center;

    &-details {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 2px;

      &-name {
        font-weight: 500;
        font-size: 18px;
        line-height: 120%;
        letter-spacing: -0.54px;
        color: colors.$navy-blue;

        &.product-testimonial {
          line-height: 140%;
          font-weight: 400;
        }
      }

      &-score {
        font-size: 20px;
        font-weight: 600;
        line-height: 120%;
        letter-spacing: -0.6px;
        color: colors.$navy-blue;
      }

      &-company {
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        letter-spacing: -0.48px;
        color: rgba($color: colors.$navy-blue, $alpha: 0.8);

        &.product-testimonial {
          font-weight: 400;
          line-height: 140%;
          color: rgba($color: colors.$navy-blue, $alpha: 0.6);
        }
      }

      &-product {
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: -0.48px;
        color: rgba($color: colors.$navy-blue, $alpha: 0.8);
      }
    }
  }

  &-bottom {
    &.product-testimonial {
      display: flex;
      align-items: flex-end;
      align-self: stretch;
      justify-content: space-between;
      border-top: 1px solid rgba($color: colors.$navy-blue, $alpha: 0.1);
      padding-top: 24px;

      &.mobile {
        border-top: 0;
        padding-top: 0;
        min-height: 48px;
      }

      @media #{variables.$desktop-wide} {
        padding-top: 0;
        min-height: 48px;
        border: 0;
      }
    }
  }
}
