$heading-font: var(--mulish-font), 'Trebuchet MS', Tahoma, Arial, sans-serif;
$body-font: var(--mulish-font), 'Trebuchet Sans MS', Tahoma, Arial, sans-serif;
$hankenGrotesk-font: var(--hankenGrotesk-font), 'Trebuchet Sans MS', Tahoma, Arial, sans-serif;

$body-color: #343a40;
$light-body-color: rgba(0, 0, 0, 0.5);
$heading-color: #343a40;
$primary-color: #3e97d7;
$link-color: #167bc4;

// Responsive Variables
$desktop-wide-md: 'only screen and (min-width: 1640px) and (max-width: 2100px)';
$desktop-wide: 'only screen and (min-width: 1640px)';
$desktop-to-wide: 'only screen and (min-width: 1400px) and (max-width: 1640px)';
$desktop: 'only screen and (min-width: 1400px)';
$above-laptop: 'only screen and (min-width: 1200px)';
$laptop: 'only screen and (min-width: 1200px) and (max-width: 1399px)';
$lg-to-desktop: 'only screen and (min-width: 992px) and (max-width: 1400px)';
$above-lg: 'only screen and (min-width: 992px)';
$lg: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$above-md: 'only screen and (min-width: 768px)';
$md: 'only screen and (min-width: 768px) and (max-width: 991px)';
$above-xs: 'only screen and (min-width: 767px) and (max-width: 825px)';
$xs: '(max-width: 767px)';
$sm: 'only screen and (min-width: 575px) and (max-width: 767px)';
$above-mobile: 'only screen and (min-width: 428px)';
$above-md-height: '(max-height: 750px)';

// $imgix: 'https://ht-brian.imgix.net';
$font-weight-normal: 400;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-bolder: 800;

$headings-line-height: 1.22;
