$imgix: 'https://ht-prod.imgix.net';
@use '../../../../../styles/variables' as variables;

.container {
  display: flex;
  flex-wrap: wrap;
  padding: 24px 48px;
  gap: 16px;

  @media #{variables.$xs} {
    padding: 0px 16px;
    padding-top: 20px;
    gap: 20px;
  }

  @media #{variables.$desktop-wide} {
    padding: 32px 96px;
    gap: 24px;
  }

  .card {
    display: flex;
    flex: 0 0 100%;
    height: 400px;

    @media #{variables.$xs} {
      max-height: 240px;
    }

    @media #{variables.$above-md} {
      flex: 0 0 calc(50% - 8px);
    }

    @media #{variables.$desktop-wide} {
      flex: 0 0 calc(50% - 12px);
      height: 580px;
    }

    &--wide {
      flex: 0 0 100%;

      @media #{variables.$xs} {
        max-height: 361px;
      }
    }

    &--narrow {
      h6 {
        @media #{variables.$md,variables.$lg} {
          max-width: 100%;
        }
      }
    }
  }
}
