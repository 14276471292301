$imgix: 'https://ht-prod.imgix.net';
@use '../../../../../styles/variables' as variables;
@use '../../../../../styles/colors' as colors;

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;

  @media #{variables.$sm} {
    height: 100%;
  }

  .outer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;
      position: relative;
      width: 100%;
      height: 100%;
      padding: 0 14px 52px 15px;

      @media #{variables.$above-md} {
        padding: 0 48px 80px 48px;
      }

      @media #{variables.$desktop-wide} {
        padding: 0 96px 126px 96px;
      }

      @media #{variables.$xs} {
        height: calc(var(--mobileHeight, 1vh) * 100);
      }

      .top {
        position: relative;
        height: auto;
        width: 100%;

        @media #{variables.$above-lg} {
          height: auto;
        }

        div.space1 {
          height: 103px;

          @media #{variables.$above-lg} {
            max-height: 120px;
            height: 100%;
          }
        }

        h1.title {
          position: relative;
          font-size: 80px;
          font-weight: 600;
          line-height: 110%;
          letter-spacing: -2.4px;
          text-align: left;
          color: colors.$orange-50;
          margin-bottom: 0;
          width: 600px;

          @media #{variables.$xs} {
            width: 100%;
            max-width: 394px;
            font-size: 48px;
            letter-spacing: -1.44px;
          }

          @media #{variables.$desktop-wide} {
            width: 884px;
            font-size: 108px;
            letter-spacing: -3.24px;
          }
        }

        div.space2 {
          height: 32px;

          @media #{variables.$above-lg} {
            height: 40px;
          }

          @media #{variables.$desktop-wide} {
            height: 64.662px !important;
          }
        }

        &-content {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 40px;

          @media #{variables.$xs} {
            gap: 32px;
          }

          @media #{variables.$desktop-wide} {
            gap: 80px;
          }
        }
      }
    }

    .bottom {
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }
}
