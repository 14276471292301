$imgix: 'https://ht-prod.imgix.net';
@use '../../../../../styles/variables' as variables;
@use '../../../../../styles/colors' as colors;

$spacing: (
  mobile: 20px,
  desktop: 48px,
  wide: 96px,
);

.ht-hearing-aid {
  flex-direction: column;
  display: flex;
  background-color: colors.$orange-50;
  //gap: map-get($spacing, desktop);
  padding: 96px 48px;

  @media #{variables.$xs} {
    // gap: 3rem;
    padding: 4rem 20px 2rem;
  }

  @media #{variables.$desktop-wide} {
    //gap: 64px;
    padding: 128px 96px 3rem;
  }

  &-header {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    @media #{variables.$desktop-wide} {
      //gap: 48px;
    }

    &-tabs {
      display: flex;
      align-items: flex-start;
      overflow-x: scroll;
      overflow-y: hidden;
      scrollbar-width: none;
      width: calc(100% + map-get($spacing, desktop));
      gap: 10px;

      @media #{variables.$xs} {
        width: calc(100% + map-get($spacing, mobile));
      }

      @media #{variables.$desktop-wide} {
        width: calc(100% + map-get($spacing, wide));
        gap: 16px;
      }

      .tab-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: fit-content;
        border-radius: 60px;
        font-weight: 400;
        padding: 10px 18px;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: -0.48px;
        gap: 8px;
        max-height: 42px;
        border: 1px solid rgba($color: colors.$navy-blue, $alpha: 0.2);
        cursor: pointer;

        &--active {
          border: 0;
          color: white;
          background: colors.$primary-blue;
        }

        @media #{variables.$xs} {
          padding: 8px 16px;
          letter-spacing: -0.42px;
          gap: 10px;
          max-height: 36px;
        }

        @media #{variables.$desktop-wide} {
          padding: 16px 28px;
          font-size: 20.8px;
          line-height: 100%;
          letter-spacing: -0.624px;
          gap: 16px;
          max-height: 53px;
        }
      }
    }
  }

  .carousel {
    @media #{variables.$xs} {
      width: calc(100% + map-get($spacing, mobile));
    }

    @media #{variables.$above-md} {
      width: calc(100% + map-get($spacing, desktop));
    }

    @media #{variables.$desktop-wide} {
      width: calc(100% + map-get($spacing, wide));
    }
  }
}
