$imgix: 'https://ht-prod.imgix.net';
@use '../../styles/colors' as colors;
@use '../../styles/variables' as variables;

.heading {
  line-height: 1.2em;
  color: colors.$white;
  font-family: var(--hankenGrotesk-font);
  em {
    color: colors.$sky-blue-400;
    font-style: normal;
  }
  &--h1 {
    font-weight: 600;
    font-size: 4.5rem;
  }
  &--h2 {
    font-weight: 600;
    font-size: 4rem;
  }
  &--h3 {
    font-weight: 600;
    font-size: 3.4rem;
  }
  &--h4 {
    font-weight: 600;
    font-size: 2.85rem;
  }
  &--h5 {
    font-weight: 600;
    font-size: 2.285rem;
  }
  &--h6 {
    font-weight: 600;
    font-size: 1.7rem;
  }
}
