$imgix: 'https://ht-prod.imgix.net';
@use '../../../../../styles/variables' as variables;
@use '../../../../../styles/colors' as colors;

.hero-reviews {
  color: colors.$white;
  padding: 128px 72px 96px 72px;
  background-color: colors.$navy-blue;
  display: flex;
  flex-direction: column;
  gap: 96px;

  @media #{variables.$xs} {
    gap: 80px !important;
    padding: 80px 20px 3.5rem 20px;
  }

  @media #{variables.$desktop-wide} {
    padding: 128px 96px 96px 96px;
  }

  .headingContainer {
    display: flex;
    justify-content: center;

    .heading {
      width: 802px;
      text-align: center;
      font-size: 64px;
      font-weight: 600;
      line-height: 120%;
      letter-spacing: -1.92px;

      @media #{variables.$xs} {
        max-width: calc(100% - 20px);
        font-size: 48px;
        line-height: 110%;
        letter-spacing: -1.44px;
      }

      @media #{variables.$desktop-wide} {
        font-size: 80px;
        line-height: 110%;
        letter-spacing: -2.4px;
      }
    }
  }

  .container {
    gap: 64px;
    display: flex;

    @media #{variables.$xs} {
      flex-direction: column;
      gap: 80px;
    }

    @media #{variables.$desktop-wide} {
      gap: 96px;
    }

    & > * {
      display: flex;
      flex-direction: column;
      gap: 32px;
      flex: 1;

      @media #{variables.$desktop-wide} {
        gap: 48px;
      }
    }
  }

  .containerContent {
    border-bottom: 1px solid rgba($color: white, $alpha: 0.4);
    padding-bottom: 40px;

    @media #{variables.$desktop-wide} {
      padding-bottom: 60px;
    }
  }

  .column-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .column-header {
      font-size: 32px;
      font-weight: 600;
      line-height: 120%;
      letter-spacing: -0.96px;

      @media #{variables.$xs} {
        font-size: 24px;
        letter-spacing: -0.72px;
      }

      &-link {
        color: colors.$sky-blue-400;
        font-size: 20px;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: -0.6px;
        cursor: pointer;

        @media #{variables.$xs} {
          font-size: 18px;
          font-weight: 500;
          line-height: 120%;
          letter-spacing: -0.54px;
        }
      }
    }
  }

  .column {
    gap: 32px;
    display: flex;
    flex-direction: column;

    @media #{variables.$above-md} {
      margin-top: 32px;
    }

    @media #{variables.$desktop-wide} {
      gap: 48px;
      margin-top: 48px;
    }
  }

  .logos {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .logoText {
      display: flex;
      justify-content: center;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 140%;
      letter-spacing: -0.42px;

      @media #{variables.$desktop-wide} {
        font-size: 1.3em;
        letter-spacing: -0.48px;
        margin-top: 2rem;
      }
    }
    .logoContainer {
      margin: 15px -72px;
      @media #{variables.$xs} {
        margin: 13px -20px;
      }

      @media #{variables.$desktop-wide} {
        margin: 22px -96px;
      }

      .logoImage {
        max-height: 100px;
        max-width: 120px;
        width: 100%;
        height: 100%;
        @media #{variables.$xs} {
          padding: 0 1.5rem;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        // @media #{variables.$desktop-wide} {
        //   max-width: 80px;
        // }
      }
    }

    .logo-slider {
      &--fit {
        width: 100%;
      }

      &-item {
        &--fit {
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  .articleFeatureCard {
    height: 100%;

    @media #{variables.$desktop-wide} {
      gap: 28px;
    }

    header {
      @media #{variables.$above-lg} {
        max-height: 360px;
        aspect-ratio: 1;
      }

      @media #{variables.$desktop-wide} {
        min-height: 545px;
        border-radius: 30px;
      }

      @media #{variables.$xs} {
        min-height: 260px;
      }

      section {
        min-width: 80px;
        @media #{variables.$desktop-wide} {
          min-width: 120px;
          margin: 18px !important;
        }
      }
    }

    header > a {
      padding: 20px !important;

      @media #{variables.$xs} {
        margin: 0 12px 13px auto !important;
      }

      @media #{variables.$desktop-wide} {
        padding: 24px !important;
        margin: 0 19px 22px auto !important;

        i {
          width: 36px;
          height: 36px;
        }
      }
    }

    section > h5 {
      font-size: 24px !important;
      font-weight: 500;
      line-height: 140% !important;
      letter-spacing: -0.72px;

      @media #{variables.$desktop-wide} {
        font-size: 36.214px !important;
        letter-spacing: -1.086px;
        margin: 0 0 9px 0 !important;
      }
    }

    section > p {
      color: var(--White-Accents-White---80, rgba(255, 255, 255, 0.8));
      font-size: 16px;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: -0.48px;
      -webkit-line-clamp: 2;

      @media #{variables.$desktop-wide} {
        font-size: 24.143px;
        letter-spacing: -0.724px;
      }
    }
  }
}

.secondaryArticleCard {
  margin-bottom: auto;

  @media #{variables.$xs} {
    gap: 20px;
    margin: 4px;
  }

  @media #{variables.$desktop-wide} {
    gap: 28px !important;
  }

  header {
    min-height: 160px;
    width: fit-content !important;
    max-width: 240px;

    @media #{variables.$xs} {
      border-radius: 10px !important;
      max-width: 138px;
      min-height: 104px;
    }

    @media #{variables.$desktop-wide} {
      min-width: 362px !important;
      min-height: 242px;
      border-radius: 30px !important;
    }

    section {
      min-width: 48px !important;
      @media #{variables.$xs} {
        margin: 4px !important;
      }
      @media #{variables.$desktop-wide} {
        min-width: 72px !important;
      }
    }
  }

  section > h5 {
    font-size: 24px !important;
    font-weight: 600;
    line-height: 120% !important;
    letter-spacing: -0.72px;

    @media #{variables.$xs} {
      font-size: 18px !important;
      font-weight: 500;
      letter-spacing: -0.54px;
    }

    @media #{variables.$desktop-wide} {
      font-size: 36.214px !important;
      letter-spacing: -1.086px;
      margin: 0 0 9px 0 !important;
    }
  }

  section > p {
    color: var(--White-Accents-White---80, rgba(255, 255, 255, 0.8));
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.48px;

    @media #{variables.$xs} {
      font-size: 14px;
      font-weight: 500;
      line-height: 140%;
      letter-spacing: -0.42px;
    }

    @media #{variables.$desktop-wide} {
      font-size: 24.143px;
      letter-spacing: -0.724px;
    }
  }

  a {
    @media #{variables.$xs} {
      padding: 12px !important;
      margin: 0 7px 7px auto !important;
      i {
        width: 8px;
        height: 8px;
      }
    }

    @media #{variables.$desktop-wide} {
      padding: 21px !important;
      margin: 0 19px 22px auto !important;

      i {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.secondaryArticleCard > section {
  flex-grow: 1;

  @media #{variables.$xs} {
    min-width: auto !important;
  }
}
