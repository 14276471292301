$imgix: 'https://ht-prod.imgix.net';
@use '../../../../../../styles/variables' as variables;
@use '../../../../../../styles/colors' as colors;

.background {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  overflow: hidden;
  height: 100%;
  background-size: cover;
  background-position: center;

  @media #{variables.$xs} {
    left: 0;
    top: 0;
    transform: translate(0, 0);
    height: calc(var(--mobileHeight, 1vh) * 100);
  }

  .video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(6, 12, 23, 0) 55%, #070d19 100%);
    background-position: -652.931px 0px;
    background-size: 367.714% 100%;
    background-repeat: no-repeat;

    @media #{variables.$above-lg} {
      opacity: 0.6;
      background: linear-gradient(118deg, #0b1528 24.08%, rgba(20, 37, 70, 0) 38.44%);
      background-position: 0px 0px;
      background-size: 151.86% 142.962%;
    }
  }
}
