$imgix: 'https://ht-prod.imgix.net';
@use '../../../../../styles/variables' as variables;
@use '../../../../../styles/colors' as colors;

.ht-product-card {
  margin-bottom: 2rem;
  p,
  span {
    padding: 0;
    margin: 0;
  }

  position: relative;
  max-width: 357px;
  height: 371px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  flex-shrink: 0;
  font-style: normal;

  @media #{variables.$desktop-wide} {
    max-width: 424px;
    height: 100%;
  }

  .image {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    width: 100%;
    padding: 12px;
    height: 240px;
    border-radius: 20px;
    background: colors.$white;
    border: 1px solid rgba(colors.$navy-blue, 0.1);

    @media #{variables.$desktop-wide} {
      height: 286px;
    }

    a {
      //width: 50%;
      height: 75%;
      @media #{variables.$xs} {
        height: 85%;
      }
      object-fit: contain;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .row {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .col {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
    }

    .title {
      font-size: 24px;
      letter-spacing: -0.72px;
      line-height: 120%;
      color: colors.$navy-blue;
      font-weight: variables.$font-weight-semibold;

      @media #{variables.$above-md} {
        font-size: 20px;
        letter-spacing: -0.6px;
      }

      @media #{variables.$desktop-wide} {
        font-size: 24px;
        letter-spacing: -0.713px;
      }
    }

    .availability {
      display: none;

      @media #{variables.$above-md} {
        display: block;
        color: rgba(colors.$navy-blue, 0.8);
        font-weight: variables.$font-weight-normal;
        font-size: 14px;
        line-height: 140%;
        letter-spacing: -0.42px;
      }

      @media #{variables.$desktop-wide} {
        font-size: 16px;
        letter-spacing: -0.499px;
      }
    }

    .price {
      color: rgba(colors.$navy-blue, 0.95);
      font-weight: 500;
      font-size: 16px;
      line-height: 140%;
      letter-spacing: -0.48px;

      span {
        color: rgba(colors.$navy-blue, 0.95);
        font-weight: variables.$font-weight-semibold;
        font-size: 16px;
        line-height: 130%;
        letter-spacing: -0.48px;
        font-weight: 300;

        @media #{variables.$desktop-wide} {
          font-size: 19px;
          line-height: 140%;
          letter-spacing: -0.57px;
        }
      }
    }

    .description {
      max-width: 315px;
      color: rgba(colors.$navy-blue, 0.6);
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      letter-spacing: -0.42px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;

      @media #{variables.$desktop-wide} {
        max-width: 375px;
        font-size: 16.625px;
        letter-spacing: -0.499px;
      }

      .see-more {
        color: colors.$primary-blue;
        font-weight: 500;
        line-height: 140%;
        letter-spacing: -0.42px;
        text-decoration-line: underline;
      }
    }
  }

  .infoButton {
    cursor: pointer;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 12px;
    right: 12px;
    border: 0;
    background: rgba($color: colors.$navy-blue, $alpha: 0.05);
    backdrop-filter: blur(24px);
    border-radius: 70px;
    padding: 14px;

    @media #{variables.$desktop-wide} {
      backdrop-filter: blur(30px);
      padding: 18px;
      border-radius: 92px;
    }
    @media #{variables.$xs} {
      backdrop-filter: blur(13px);
      border-radius: 35px;
    }

    i {
      background-position: center;
      width: 18px;
      height: 18px;

      @media #{variables.$desktop-wide} {
        width: 24px;
        height: 24px;
      }
    }
  }
}
