$imgix: 'https://ht-prod.imgix.net';
@use '../../../styles/variables' as variables;

.root {
  gap: 19px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;

  &.root-extended {
    flex-direction: column;
    .header {
      width: 100%;
    }
  }

  .header.articleCard {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      left: 0;
      top: 0;
    }
  }

  .header {
    width: 40%;
    min-width: 138px;
    overflow: hidden;
    aspect-ratio: 7/4;
    position: relative;
    border-radius: 20px;
    background-color: white;
    //border:1px solid white;
    //background-color: #ffffff29;
    //opacity:0.8;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    //background: #f7f7f7;

    img {
      width: 90%;
      height: 90%;
      object-fit: contain;
      position: absolute;
      top: 5%;
      left: 5%;
    }

    .iconButtonLink {
      @media #{variables.$xs} {
        display: none;
      }
      z-index: 1;
      padding: 16px;
      border-radius: 100%;
      align-self: flex-end;
      margin: 0 13px 15px auto;
      background-color: rgba(255, 255, 255, 1);
      box-shadow: 4px 4px 32px rgba(0, 0, 0, 0.15);
      .svg-icon {
        width: 24px;
        height: 24px;
      }
    }

    .cardHeaderScore {
      margin: 12px;
    }
  }

  .content {
    flex: 1;
    min-width: 220px;
    .title {
      font-size: 1.5rem;
      line-height: 1.4em;
      margin: 0 0 6px 0;
      font-family: var(--hankenGrotesk-font);
    }
    .body {
    }
  }
}

@container (max-width: 525px) {
  .root {
    flex-direction: column;
    .header,
    .content {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    }
  }
}
