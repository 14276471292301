$imgix: 'https://ht-prod.imgix.net';
@use '../../../../../../styles/variables' as variables;
@use '../../../../../../styles/colors' as colors;

$spacing: (
  mobile: 18px,
  desktop: 48px,
  wide: 96px,
);

.ht-hero-lower-content {
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: start;

  @media #{variables.$xs} {
    gap: 24px;
  }

  .title {
    color: colors.$navy-blue;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: -0.6px;

    @media #{variables.$xs} {
      font-size: 18px;
      line-height: 120%;
      letter-spacing: -0.54px;
    }
  }

  .cardContainer {
    display: flex;
    gap: 24px;
  }

  .carouselContainer {
    width: calc(100% + map-get($spacing, desktop));

    @media #{variables.$xs} {
      width: calc(100% + map-get($spacing, mobile));
    }
  }
}
