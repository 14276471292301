$imgix: 'https://ht-prod.imgix.net';
@use '../../../../../styles/variables' as variables;
@use '../../../../../styles/colors' as colors;

$base-size: 24px;
$inline-size: calc($base-size - 4px);
$block-size: $base-size;

.ht-feature-cta-card {
  &--default,
  &--extended {
    position: relative;
    overflow: hidden;

    .image {
      position: absolute;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .title {
      font-size: $base-size;
      font-weight: 500;
      margin-bottom: 8px;
      color: colors.$navy-blue;
    }

    .action {
      font-weight: variables.$font-weight-semibold;
      color: variables.$body-color;
      position: absolute;
      z-index: 2;
      display: flex;
      justify-content: end;
      align-items: center;
      gap: 16px;
      // display:none;

      .iconButtonLink {
        border-radius: 100%;
        display: grid;
        place-items: center;
        height: 56px;
        aspect-ratio: 1;
        background-color: colors.$white;

        i {
          width: 24px;
          height: 24px;
          color: colors.$navy-blue;
        }
      }
    }
  }

  &--default {
    max-width: 318px;
    height: 197px;
    padding: 20px 24px;
    border-radius: 30px;

    @media #{variables.$above-mobile} {
      width: 100% !important;
    }

    @media #{variables.$desktop} {
      max-width: 100%;
      width: 100% !important;
    }

    @media #{variables.$desktop-wide} {
      height: 260px !important;
    }

    .image {
      height: 160%;
      right: 0;
      bottom: 0;
      transform: translate(50%, 50%);
      aspect-ratio: 1;
      border-radius: 100%;
      @media #{variables.$above-mobile} {
        height: 140%;
      }
      img {
        // display:none;
        // background-blend-mode: normal, darken;
        // box-shadow: 12.114px 0px 15.143px 0px rgba(0, 0, 0, 0.1) inset;
        // filter: drop-shadow(12.114px 0px 15.143px rgba(0, 0, 0, 0.1));
      }
    }

    .title {
      max-width: 220px;
      line-height: 120%;
      letter-spacing: -0.96px;
      overflow: hidden;

      @media #{variables.$desktop-wide} {
        max-width: 340px;
        font-size: 40px;
      }
    }

    .action {
      left: 12px;
      bottom: 12px;

      .hint {
        display: none;
      }
    }

    &.merigold {
      background: colors.$merigold;
      .image {
        background: colors.$merigold-offset;
      }
    }

    &.sky {
      background: colors.$sky;
      .image {
        background: colors.$sky-offset;
      }
    }

    &.lime {
      background: colors.$lime;
      .image {
        background: colors.$lime-offset;
      }
    }

    &.lavender {
      background: colors.$lavender;
      .image {
        background: colors.$lavender-offset;
      }
    }
  }

  &--extended {
    width: 100%;
    height: 100%;
    padding: 24px;
    min-height: 400px;
    border-radius: 20px;

    @media #{variables.$xs} {
      min-height: 240px;
      max-height: 361px;
    }

    @media #{variables.$desktop-wide} {
      padding: 40px;
      min-height: 580px;
    }

    @media only screen and (min-width: 2160px) {
      width: 100%;
      height: 580px;
    }

    .image {
      inset: 0;
      height: 100%;
      width: 100%;
      z-index: 1;

      .overlay {
        position: absolute;
        inset: 0;
        z-index: 3;
        background: linear-gradient(102deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 61.58%, rgba(0, 0, 0, 0.31) 100%);
      }
    }

    .title {
      font-size: 32px;
      color: colors.$white;
      line-height: 120%;
      letter-spacing: -0.96px;
      position: relative;
      z-index: 2;
      text-align: start;

      @media #{variables.$above-md} {
        font-size: 40px;
        letter-spacing: -1.2px;
        max-width: 50%;
      }

      @media #{variables.$desktop-wide} {
        font-size: 48px;
        letter-spacing: -1.44px;
      }
    }

    .action {
      position: absolute;
      z-index: 2;

      @media #{variables.$above-md} {
        right: 26px;
        bottom: 26px;
      }

      right: 24px;
      bottom: 24px;

      .hint {
        margin: 0;
        font-size: 18px;
        line-height: 120%;
        letter-spacing: -0.54px;
        font-weight: 400;
        color: colors.$white;
      }
    }
  }
}
