$imgix: 'https://ht-prod.imgix.net';
@use '../../../../../styles/variables' as variables;
@use '../../../../../styles/colors' as colors;

.buttons {
  width: 100%;
  font-size: 18px;
  position: relative;
  z-index: 3;

  @media #{variables.$above-lg} {
    display: flex;
    flex-direction: row;
    gap: 19px;
    width: auto;
  }

  @media #{variables.$desktop-wide} {
    gap: 21.939px;
  }

  a.compare {
    position: absolute;
    left: 0px;
    display: flex;
    align-items: center;
    height: 46px;
    padding: 12px 24px;
    border-radius: 60px;
    background-color: #fff;
    text-align: center;
    text-decoration: none;
    gap: 8px;
    width: 220px;

    @media #{variables.$desktop-wide} {
      padding: 17.32px 34.64px;
      border-radius: 86.601px;
      height: auto;
      width: auto !important;
    }

    @media #{variables.$above-lg} {
      position: relative;
      bottom: 0;
      width: 228px;
    }

    &:hover {
      background-color: colors.$sky-blue-400;
    }

    &:active {
      background-color: colors.$primary-blue;
    }

    span {
      font-family: var(--hankenGrotesk-font);
      font-size: 18px;
      font-weight: 400;
      line-height: 120%;
      letter-spacing: -0.54px;
      text-align: center;
      color: colors.$navy-blue;

      @media #{variables.$desktop-wide} {
        font-size: 25.98px;
        letter-spacing: -0.779px;
      }
    }
  }

  a.new {
    display: none;
    width: 232px;
    height: 46px;
    padding: 12px 24px;
    gap: 8px;
    text-align: center;
    text-decoration: none;
    border-radius: 60px;
    background: rgba($color: white, $alpha: 0.1);
    backdrop-filter: blur(25px);

    &:hover {
      background: rgba($color: white, $alpha: 0.2);
    }

    @media #{variables.$desktop-wide} {
      padding: 17.32px 34.64px;
      border-radius: 86.601px;
      height: auto;
      width: auto;
    }

    @media #{variables.$above-lg} {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    span {
      font-family: var(--hankenGrotesk-font);
      font-size: 18px;
      font-weight: 400;
      line-height: 120%;
      letter-spacing: -0.54px;
      text-align: center;
      color: white;

      @media #{variables.$desktop-wide} {
        font-size: 25.98px;
        letter-spacing: -0.779px;
      }
    }
  }
}
