$imgix: 'https://ht-prod.imgix.net';
@use '../../../../styles/colors.scss' as colors;

.ht-audio-button {
  position: relative;
  width: 48px;
  aspect-ratio: 1;
  padding: 4.52px;
  border-radius: 100%;
  border: none;
  background-color: colors.$white;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.05);

  .button {
    position: absolute;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;

    i {
      width: 13px;
      aspect-ratio: 1;
      margin-left: 3px;
      color: colors.$navy-blue;
    }
  }
}
