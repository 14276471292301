$imgix: 'https://ht-prod.imgix.net';
@use '../../../../../../styles/variables' as variables;
@use '../../../../../../styles/colors' as colors;

.carousel {
  position: relative;
  overflow: visible !important;

  @media #{variables.$md} and #{variables.$above-md-height} {
    display: none !important;
  }

  @media #{variables.$above-md} {
    position: absolute;
    bottom: 80px;
    margin-left: calc(100% - 456px) !important;
    width: 360px !important;
    overflow: hidden !important;
  }

  @media #{variables.$desktop-wide} {
    bottom: 126px;
    width: 452px !important;
    margin-left: calc(100% - 644px) !important;
    overflow: hidden !important;
  }

  @media #{variables.$xs } {
    margin: 0 -14px 0 -14px;
  }

  ul {
    position: relative;

    @media #{variables.$above-md} {
      width: 360px !important;
    }

    @media #{variables.$desktop-wide} {
      width: 452px !important;
    }

    @media #{variables.$xs } {
      li {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 14px 0 14px;
      }
    }
  }

  .item-container {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    @media #{variables.$xs } {
      backdrop-filter: blur(50px);
      border-radius: 15px;
      width: 100% !important;
    }

    .item {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 17px;
      color: #fff;
      text-decoration: none;

      @media #{variables.$xs } {
        flex-direction: row-reverse;
        padding: 16px;
        border-radius: 15px;
        background: rgba($color: colors.$navy-blue, $alpha: 0.1);
        gap: 20px;
      }

      .text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 6px;
        overflow: hidden !important;

        @media #{variables.$xs } {
          gap: 4px;
        }

        .smallText {
          font-size: 14px;
          letter-spacing: -0.42px;
          font-weight: 500;
          line-height: 140%;
          text-align: left;
          text-decoration: none;
          opacity: 80%;

          @media #{variables.$above-md } {
            font-size: 16px;
            letter-spacing: -0.48px;
          }

          @media #{variables.$desktop-wide} {
            font-size: 20.089px;
            letter-spacing: -0.603px;
          }
        }

        .title {
          font-size: 18px;
          letter-spacing: -0.54px;
          font-weight: 500;
          line-height: 140%;
          letter-spacing: -0.72px;
          text-align: left;
          text-decoration: none;

          @media #{variables.$above-md } {
            font-size: 24px;
            letter-spacing: -0.72px;
          }

          @media #{variables.$desktop-wide} {
            font-size: 30.133px;
            letter-spacing: -0.904px;
          }
        }
      }

      .image-container {
        position: relative;

        .image {
          position: relative;

          img {
            border-radius: 10px;
            border: 2px solid rgb(255 255 255 / 70%);

            @media #{variables.$above-md } {
              border-radius: 20px;
            }

            @media #{variables.$desktop-wide} {
              border-radius: 25.111px;
            }
          }
        }
      }
    }
  }

  .arrows {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;

    .arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      background-color: #fff;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      bottom: 11px;
      border: none;
      outline: none;

      @media #{variables.$above-md } {
        width: 48px;
        height: 48px;
        bottom: 14.25px;
      }

      @media #{variables.$desktop-wide} {
        width: 60.267px;
        height: 60.267px;
        bottom: 17px;
      }
    }

    .right {
      right: 10px;

      @media #{variables.$above-md } {
        right: 13px;
      }

      @media #{variables.$desktop-wide} {
        right: 16px;
      }
    }
  }
}

.custom-dot-list-small {
  @media #{variables.$xs } {
    padding: 24px 18px 28px 14px !important;
  }
}

.custom-dot-list {
  width: 360px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  height: 10px;
  bottom: auto;

  @media #{variables.$md} and #{variables.$above-md-height} {
    display: none !important;
  }

  @media #{variables.$above-md} {
    left: calc(100% - 408px);
    padding: 32px 0px 48px 0px;
  }

  @media #{variables.$xs } {
    gap: 8px !important;
    max-width: 399px;
    width: 100%;
    align-items: center !important;
    margin: auto !important;
    padding: 24px 0px 28px 0px;
  }

  @media #{variables.$desktop-wide} {
    width: 452px;
    gap: 12px;
    left: calc(100% - 548px);
    padding: 40px 0px 86px 0px;
  }
}

.underline {
  position: relative;

  button {
    position: absolute;
    left: 3px;
    top: -3px;
    width: 100%;
    background-color: transparent;
    outline: none;
    border: none;
    padding: 3px;

    .display {
      border-radius: 3px;
      background-color: #98c1ff;
      opacity: 0.1;
      height: 4px;

      @media #{variables.$above-md } {
        height: 6px;
      }

      @media #{variables.$desktop-wide} {
        height: 8px;
      }
    }

    .display.active {
      opacity: 1;
    }

    :hover {
      opacity: 1;
    }
  }
}
